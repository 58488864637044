<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 猜鼻子配置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="form-box">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="是否开启活动">
                        <el-switch v-model="form.status"></el-switch>
                    </el-form-item>
                    <el-form-item label="游戏次数">
                        <el-input v-model="form.frequency" placeholder="请输入游戏次数"></el-input>
                    </el-form-item>
                    <el-form-item label="开始日期">
                        <el-date-picker
                                v-model="form.startdate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="截止日期">
                        <el-date-picker
                                v-model="form.enddate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="活动规则">
                        <el-input type="textarea" style="width:600px;" :rows="10" v-model="form.rule"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

    </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor, Quill} from 'vue-quill-editor'
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend)
    export default {
        name: 'baseform',
        components: {
            quillEditor
        },
        data: function(){
            return {
                form:{
                    id: '',
                    status: '',
                    frequency: '',
                    startdate: '',
                    enddate: '',
                    rule: '',
                },
                loading:false,
                // 富文本框参数设置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'image',
                            action: this.$api.uploadUrl+"/Images/uploadEditorImage",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            onEditorChange({ editor, html, text }) {
                this.form.details = html;
            },
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                // console.log(file);
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //转盘图片上传成功
            handleAvatarSuccess1(res, file){
                this.loading=false;
                // console.log(res);
                this.form.zpimgid=res.data;
                this.form.zpimgUrl = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            //标题图片上传成功
            handleAvatarSuccess2(res, file){
                this.loading=false;
                // console.log(res);
                this.form.titleimgid=res.data;
                this.form.titleimgUrl = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            //获取初始数据
            getData() {
                //不传参数要写null
                this.$api.post('NoseConfig/getConfig', null, res => {
                    this.form = res.data;
                    // console.log(res);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
            },
            onSubmit() {
                var params=this.$qs.stringify({
                    id: this.form.id,
                    status: this.form.status ? 1 : 0,
                    frequency: this.form.frequency,
                    startdate: this.form.startdate,
                    enddate: this.form.enddate,
                    rule: this.form.rule,
                });
                this.$api.post('NoseConfig/saveConfig', params, res => {
                    this.$message.success(res.msg);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.form.details);
                // console.log(str);
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-upload--picture-card{
        width: 100% !important;
        height: auto !important;
    }
</style>